import React from "react"
import Menu from "./menu"

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  render() {
    return (
      <>
        <button
          className={`${this.state.open ? `menu-open` : ""}`}
          onClick={() => this.toggleMenu()}
        >
          <span>Öppna menyn</span>
        </button>

        <Menu ref={el => (this.childMenu = el)} />

      </>
    )
  }

  toggleMenu() {
    if (this.state.open) {
      this.childMenu.close()
      this.setState({ open: false })
    }
    else {
      this.childMenu.open()
      this.setState({ open: true })
    }
  }

  reset() {
    this.setState({open: false})
  }

}

export default Navigation
