import "../css/typography.css"

export const theme = {
  breakpoints: [576, 768, 992, 1200],

/* Font sizes for different breakpoints */
  large: ['48px','72px'],
  medium: ['36px','48px'],
  regular: ['18px','22px'],
  monoHeading: ['14px','16px'],
  monoRegular: ['16px','18px'],
  superscript: ['12px','14px'],

  lineHeightLarge: '120%',
  lineHeightMedium: '130%',
  lineHeightRegular: '150%',
  lineHeightMono: '120%',

  sansSerifFont: 'Founders Grotesk Text',
  monoFont: 'Founders Grotesk Mono',
  serifFont: 'Domaine Display',

  bodyColor: '#3A332A',
  lightBodyColor: '#F8F8F6',
  backgroundColor: '#F8F8F6',

  yellow: "#FFCC01",
  purple: "#AA00FF",
  blue: "#0C85EE",
  green: "#53C4AE",
  red: "#FF3300",
  lightGray: "#A8A5A1",

  menuClass: "menu",
  menuOpenClass: "open"
};
