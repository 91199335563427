import React from "react";
import {useStaticQuery, graphql} from "gatsby"
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Navigation from "./navigation"
import { Helmet } from "react-helmet"

export const Header = ({ siteTitle, location }) => {

/* By passing global variable location to Layout,
   pages get body class of page-{location.pathname}
   See reflektioner.js for example */

  const slug = location ? 'page'.concat('-', location.pathname.replace(/\//g,'')) : '';

  const data = useStaticQuery(graphql`query MyQuery {
    file(relativePath: {eq: "logo.png"}) {
      childImageSharp {
        gatsbyImageData(width: 183, layout: FIXED)
      }
    }
  }
  `)

  return (

    <>
      <Helmet
        title={siteTitle}
        bodyAttributes={slug ? { class: slug } : {}}
      />

      <header>
        <Link className="link-home" to="/">
          <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} className = "konfront-logo" alt = "{siteTitle}" />
        </Link>
        <title>{siteTitle}</title>
        <nav><Navigation /></nav>
      </header>
    </>
  );
};
