import React, { useImperativeHandle, useRef } from "react"
import { Link } from "gatsby"
import styles from "../css/menu.css"
import { theme } from "./theme"

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  render() {
    return (
      <div
        role="button"
        tabIndex="0"
        className={`${theme.menuClass} ${this.state.open ? `${theme.menuOpenClass}` : ""}`}
      >
        <Link to="/"><h3>Hem</h3></Link>
        <Link to="/sofia-sundstrom"><h3>Om Sofia</h3></Link>
        <Link to="/bubbles-of-bliss"><h3>Bubbles of Bliss</h3></Link>
        <Link to="/reflektioner"><h3>Reflektioner</h3></Link>

      </div>
    )
  }

  close() {
    this.setState({ open: false })
  }

  open() {
    this.setState({ open: true })
  }
  reset() {
    this.setState({ open: false })
  }
}

export default React.forwardRef((props, ref) => {
  const menuRef = useRef()

  useImperativeHandle(ref, () => ({
    open() {
      menuRef.current.open()
    },
    close() {
      menuRef.current.close()
    },
  }))

  return <Menu ref={menuRef} {...props} />
})
